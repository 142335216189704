import React, { useRef } from "react";
import Layout from "../components/Layout";
import Modal from "../components/Modal";
// import HeroImage from "../components/HeroImage";
// import { Link } from "gatsby";
// import Repubblica from "../components/Repubblica";
// import Frutta from "../components/Frutta";
import Grazie from "../components/Grazie";
// import Mix from "../components/Mix";
import Form from "../components/Form";
// import Rating from "../components/Rating";
// import Infographic from "../components/Infographic";
import Bio from "../components/Bio";
import Prodotto1 from "../components/Prodotto1";
import Prodotto2 from "../components/Prodotto2";
import Prodotto3 from "../components/Prodotto3";
// import Accordion from "../components/Accordion";
import "../css/scroller.css";
const { SocialIcon } = require("react-social-icons");
// import RecensioneSara from "../components/RecensioneSara";
// import RecensioneSimona from "../components/RecensioneSimona";
// import ReactPlayer from "react-player/file";

export default () => {
    const modalRef1 = useRef();

    return (
        <Layout>
            <main className="flex-grow flex flex-col justify-evenly m-auto py-2 px-4 md:px-32">
                <section className="flex flex-wrap flex-col-reverse md:flex-row items-center mt-6">
                    <div className="flex flex-col justify-between mx-auto md:ml-0 md:mr-auto max-w-lg text-justify">
                        <h1 className="px-4 pt-5 text-4xl md:text-5xl md:text-left text-center text-black font-serif tracking-wide">
                            Grazie per esserti iscritto!
                        </h1>
                        <p className="px-4 mt-2 text-xl text-gray-700 font-sans">
                            Riceverai a breve il tuo sconto via email.
                        </p>
                        <p className="px-4 mt-2 text-xl text-gray-700 font-sans">
                            Passo Ladro coltiva esclusivamente rispettando i{" "}
                            <span className="font-black">
                                tempi della natura
                            </span>
                            : per questo motivo, il servizio di consegna
                            cassette sarà disponibile{" "}
                            <span className="font-black">
                                {" "}
                                dalle ore 12:00 del giorno 01/06/2021
                            </span>
                            .
                        </p>
                        <p className="px-4 mt-2 text-xl text-gray-700 font-sans">
                            La disponibilità dei prodotti è{" "}
                            <span className="font-black">limitata</span>,
                            controlla la tua email per rimanere aggiornato ed
                            effettuare il tuo ordine in tempo!
                        </p>

                        <p className="px-4 mt-8 text-xl text-gray-700 font-sans">
                            Seguici su:
                        </p>
                        <div className="inline-block px-4 mt-3 text-xl text-gray-700 font-sans md:text-left text-center">
                            <span className="w-2 h-2">
                                <SocialIcon
                                    bgColor="#000"
                                    url="https://www.instagram.com/passoladro/"
                                />
                                <SocialIcon
                                    className="ml-1"
                                    bgColor="#000"
                                    url="https://www.facebook.com/passoladro/"
                                />
                                <SocialIcon
                                    className="ml-1"
                                    bgColor="#000"
                                    url="https://it.linkedin.com/company/passoladro/"
                                />
                            </span>
                        </div>

                        <Modal ref={modalRef1}>
                            <Form />
                        </Modal>
                    </div>

                    <div
                        className="mx-auto md:mr-0 md:ml-auto w-full max-w-2xl md:w-1/2"
                        alt="Passo Ladro"
                    >
                        <Grazie />
                    </div>
                </section>

                {/* <section className="flex flex-wrap items-center justify-center mt-16">
                    <span className="scroll-icon">
                        <span className="scroll-icon__dot"></span>
                    </span>
                </section>

                <section className="flex flex-wrap items-center justify-center mt-5">
                    <div className="text-center mt-5 mx-0 w-full">
                        <h1 className="mb-2 px-4 text-5xl text-center text-black font-serif tracking-wide">
                            Come funziona
                        </h1>
                        <Infographic />
                    </div>
                </section> */}

                <h2 className="mt-5 md:mt-10 mx-0 mb-2 px-4 md:text-5xl text-4xl text-center text-black font-serif tracking-wide">
                    Ti potrebbe interessare
                </h2>
                <section className="grid grid-flow-row grid-cols-1 md:grid-cols-3 gap-3 mb-10">
                    <div className="flex flex-col justify-between max-w-md justify-self-center mt-8">
                        <a
                            href="https://passoladro.it/it/prodotto/passata-di-pomodoro-tondo-liscio/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Prodotto3 />
                        </a>
                        <a
                            href="https://passoladro.it/it/prodotto/passata-di-pomodoro-tondo-liscio/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <h3 className="hover:underline mt-8 text-3xl text-center text-black font-serif tracking-wide">
                                Passata Di Pomodoro Tondo Liscio Bio
                            </h3>
                        </a>
                        {/* <h1 className="px-4 pt-5 text-5xl text-left text-black font-serif tracking-wide">
                            Recensione 1
                        </h1>
                        
                        <p className="px-4 mt-2 text-xl text-gray-700 font-sans">
                            Sottotitolo Sottotitolo Sottotitolo Sottotitolo
                        </p> */}

                        <p className="px-4 mt-2 text-xl text-justify">
                            Dopo aver raccolto i pomodori, nonna Vanna li lascia
                            cuocere a fuoco lento e li aggiusta con un tocco di
                            sale per favorirne il processo di disidratazione.
                            Sta attenta però a non disperdere le note acerbe del
                            pomodoro e si assicura che tramite una cottura breve
                            il frutto conservi il sapore, quasi crudo, che
                            contraddistingue la vera passata siciliana.
                        </p>

                        <div className="px-4 mt-4">
                            <Bio />
                        </div>

                        <p className="px-4 mt-2 text-xl text-gray-700 font-sans">
                            da €2.50
                        </p>

                        <div className="text-center">
                            <div className="px-4 mt-2 text-xl text-gray-700 font-sans">
                                <a
                                    href="https://passoladro.it/it/prodotto/passata-di-pomodoro-tondo-liscio/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <button className="hover:bg-transparent bg-black shadow-lg hover:text-black text-white py-2 px-4 border hover:border-black border-transparent">
                                        {/* <Link to="/iscriviti"> */}
                                        <h2 className="max-w-4xl text-xl mx-auto uppercase font-bold font-sans">
                                            Acquista ora
                                        </h2>
                                        {/* </Link> */}
                                    </button>
                                </a>
                            </div>
                        </div>
                        {/* <div className="text-right"></div> */}
                        {/* <Modal ref={modalRef1}>
                            <h3>Modal title 1</h3>
                        </Modal> */}
                    </div>
                    <div className="flex flex-col justify-between max-w-md justify-self-center mt-8">
                        <a
                            href="https://passoladro.it/it/prodotto/olio-extra-vergine-di-oliva/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Prodotto1 />
                        </a>
                        <a
                            href="https://passoladro.it/it/prodotto/olio-extra-vergine-di-oliva/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <h3 className="hover:underline mt-8 text-3xl text-center text-black font-serif tracking-wide">
                                Olio Extra Vergine Di Oliva Bio
                            </h3>
                        </a>
                        {/* <h1 className="px-4 pt-5 text-5xl text-left text-black font-serif tracking-wide">
                            Recensione 1
                        </h1>
                        
                        <p className="px-4 mt-2 text-xl text-gray-700 font-sans">
                            Sottotitolo Sottotitolo Sottotitolo Sottotitolo
                        </p> */}

                        <p className="px-4 mt-2 text-xl text-justify">
                            Il nostro olio è ricco di importanti proprietà
                            nutritive, che prende forza dalle terre dei Monti
                            Iblei. Nasce dalle nostre olive selezionate,
                            raccolte dalla pianta al giusto grado di maturazione
                            e mollite subito. Le varietà di olive che
                            utilizziamo, come la moresca e la verdese,
                            conferiscono all’olio intensità di fruttato, una
                            punta di dolce e una sensazione di piccante che
                            esaltano tutte le pietanze.
                        </p>

                        <div className="px-4 mt-4">
                            <Bio />
                        </div>

                        <p className="px-4 mt-2 text-xl text-gray-700 font-sans">
                            da €4.80
                        </p>

                        <div className="text-center">
                            <div className="px-4 mt-2 text-xl text-gray-700 font-sans">
                                <a
                                    href="https://passoladro.it/it/prodotto/olio-extra-vergine-di-oliva/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <button className="hover:bg-transparent bg-black shadow-lg hover:text-black text-white py-2 px-4 border hover:border-black border-transparent">
                                        {/* <Link to="/iscriviti"> */}
                                        <h2 className="max-w-4xl text-xl mx-auto uppercase font-bold font-sans">
                                            Acquista ora
                                        </h2>
                                        {/* </Link> */}
                                    </button>
                                </a>
                            </div>
                        </div>
                        {/* <div className="text-right"></div> */}
                        {/* <Modal ref={modalRef1}>
                            <h3>Modal title 1</h3>
                        </Modal> */}
                    </div>
                    <div className="flex flex-col justify-between max-w-md justify-self-center mt-8">
                        <a
                            href="https://passoladro.it/it/prodotto/caponata/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Prodotto2 />
                        </a>
                        <a
                            href="https://passoladro.it/it/prodotto/caponata/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <h3 className="hover:underline mt-8 text-3xl text-center text-black font-serif tracking-wide">
                                Caponata Bio
                            </h3>
                        </a>
                        {/* <h1 className="px-4 pt-5 text-5xl text-left text-black font-serif tracking-wide">
                            Recensione 1
                        </h1>
                        
                        <p className="px-4 mt-2 text-xl text-gray-700 font-sans">
                            Sottotitolo Sottotitolo Sottotitolo Sottotitolo
                        </p> */}

                        <p className="px-4 mt-2 text-xl text-justify">
                            È il simbolo della Sicilia nel mondo, il trionfo di
                            sua maestà la “melenzana” che noi non friggiamo ma
                            passiamo in forno. Rispettando poi con il taglio la
                            forma degli ortaggi e le consistenze attraverso la
                            cottura, diamo vita alla nostra caponata che lo chef
                            ama definire “da boccone”. Perché? Assaggiatene un
                            cucchiaio e poi ci direte.
                        </p>

                        <div className="px-4 mt-4">
                            <Bio />
                        </div>

                        <p className="px-4 mt-2 text-xl text-gray-700 font-sans">
                            €6.00
                        </p>

                        <div className="text-center">
                            <div className="px-4 mt-2 text-xl text-gray-700 font-sans">
                                <a
                                    href="https://passoladro.it/it/prodotto/caponata/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <button className="hover:bg-transparent bg-black shadow-lg hover:text-black text-white py-2 px-4 border hover:border-black border-transparent">
                                        {/* <Link to="/iscriviti"> */}
                                        <h2 className="max-w-4xl text-xl mx-auto uppercase font-bold font-sans">
                                            Acquista ora
                                        </h2>
                                        {/* </Link> */}
                                    </button>
                                </a>
                            </div>
                        </div>
                        {/* <div className="text-right"></div> */}
                        {/* <Modal ref={modalRef1}>
                            <h3>Modal title 1</h3>
                        </Modal> */}
                    </div>
                </section>

                {/* <section className="flex flex-wrap items-center justify-center mt-10 md:mt-20">
                    <div className="text-center mt-5 w-full">
                        <h1 className=" mx-0 mb-2 px-4 pt-5 text-5xl text-center text-black font-serif tracking-wide">
                            Domande frequenti
                        </h1>
                        <Accordion />
                    </div>
                </section>

                <section className="flex flex-wrap items-center justify-center mt-10 md:mt-20 mb-10">
                    <div className="sm:px-2">
                        <h1 className="px-4 pt-5 text-5xl text-center text-black font-serif tracking-wide">
                            Ultimo CTA
                        </h1>
                        <p className="px-4 mt-2 text-xl text-center text-gray-700 font-sans">
                            Sottotitolo Sottotitolo Sottotitolo Sottotitolo
                        </p>
                        <div className="px-4 mt-8 text-center">
                            <button
                                onClick={() => modalRef1.current.openModal()}
                                className="hover:bg-transparent bg-black shadow-lg hover:text-black text-white py-2 px-4 border hover:border-black border-transparent"
                            >
                                <h2 className="max-w-4xl text-xl mx-auto uppercase font-bold font-sans">
                                    Ricevi il 10% di sconto
                                </h2>
                            </button>
                        </div>
                    </div>
                </section> */}

                {/* <div className="mx-auto relative px-0 lg:px-6">
                    <video controls autoPlay muted height="720" width="1280">
                        <source src="./videos/video.webm" type="video/webm" />
                        <source src="./videos/video.mp4" type="video/mp4" />
                        https://vimeo.com/500950123
                    </video>
                </div> */}

                {/* <div className="flex items-center p-3 mx-2 bg-white rounded shadow-xs sm:mx-auto">
                    <code className="text-gray-600 text-xs leading-xl">
                        gatsby new my-project-name
                        https://github.com/melanienolan/gatsby-starter-tailwind-css
                    </code>
                </div> */}
            </main>
        </Layout>
    );
};
