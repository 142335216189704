import React, { useState, forwardRef, useImperativeHandle } from "react";
import Portal from "./Portal";

const Modal = forwardRef((props, ref) => {
    const [display, setDisplay] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            openModal: () => handleOpen(),
            closeModal: () => handleClose(),
        };
    });

    const handleOpen = () => {
        setDisplay(true);
    };

    const handleClose = () => {
        setDisplay(false);
    };

    if (display) {
        return (
            <Portal>
                <div className="modal-container modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
                    <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>

                    <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">

                        <div className="modal-content py-4 text-left px-6">
                            <div className="flex justify-between items-center pb-3">
                                <p className="text-2xl font-bold">Ricevi il tuo sconto via email</p>
                                <div className="modal-close cursor-pointer z-50" onClick={handleClose}>
                                    <svg
                                        className="fill-current text-black"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 18 18"
                                    >
                                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                                    </svg>
                                </div>
                            </div>
                            
                            {props.children}

                            {/* <div className="flex justify-end pt-2">
                                <button onClick={handleClose} className="modal-close px-4 bg-indigo-500 p-3 rounded-lg text-white hover:bg-indigo-400">
                                    Close
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </Portal>
        );
    }

    return null;
});

export default Modal;
